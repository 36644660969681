<template>
  <div>
    <h2>Appointment Summary</h2>
    <div class="filters">
      <a-range-picker
        :ranges="filterDateRangePresets"
        format="YYYY/MM/DD"
        :defaultValue="[filters.dateStart, filters.dateEnd]"
        @change="handleDateRangeChange"
      />
    </div>
    <div class="map-chart">
      <chart
        ref="chart"
        style="width: 100%; height: 500px"
        :getChartOptions="getChartOptions"
        :getData="getData"
      />
    </div>
  </div>
</template>

<script>
import { queryCalendarData } from '@/api/appointment'
import chart from './_chart.vue'
import moment from 'moment'
import { time, use } from 'echarts/core'
import { MarkPointComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
use([BarChart, MarkPointComponent])

const getTimeGroupedAppointments = (appointments) => {
  // Free排在最后
  return appointments.sort((a, b) => b.status === 'Free' ? -1 : 0).reduce((m, a) => {
    let time = moment.utc(a.start_date)
    if (time.minute() !== 0 && time.minute() !== 30) {
      time.minute(Math.floor(time.minute() / 30) * 30)
    }
    time = time.valueOf()
    if (!m[time]) {
      m[time] = [a]
      return m
    }
    const pm = m[time].filter(t => t.practitioner_id === a.practitioner_id)
    if (!pm.length) {
      // 没有practitioner的appointment,直接加入
      m[time].push(a)
      return m
    }

    if (a.status === 'Free') {
      // Free遇到Cancel，踢掉Cancel保留Free
      if (pm.find(t => t.status === 'Canceled')) {
        m[time] = [...m[time].filter(t => t.practitioner_id !== a.practitioner_id), a]
      }
    } else if (a.status === 'Canceled') {
      // （这里有其他项存在）Cancel的直接忽略
    } else {
      // 如果是其他(Missed/Confirmed)状态，删除同一个时间段内Canceled
      if (pm.find(t => t.status === 'Canceled')) {
        m[time] = [...m[time].filter(t => t.practitioner_id !== a.practitioner_id), a]
      } else {
        m[time].push(a)
      }
    }
    return m
  }, {})
}

export default {
  components: { chart },
  data () {
    return {
      filterDateRangePresets: {
        'Today': [moment().startOf('day'), moment().endOf('day')],
        'Last Week': [moment().add(-7, 'days').startOf('week'), moment().add(-7, 'days').endOf('week')],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Month': [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      },
      filters: {
        dateStart: moment().startOf('week'),
        dateEnd: moment().endOf('week'),
      }
    }
  },
  methods: {
    async getData () {
      const { appointments } = await queryCalendarData(this.filters)
      const result = {
        used: {
          initial: 0,
          followup: 0,
          free: 0,
        },
        wasted: {
          initial: 0,
          followup: 0,
          free: 0,
        },
        coming: {
          initial: 0,
          followup: 0,
          free: 0
        }
      }
      const now = moment()
      const timeGroupedAppointments = getTimeGroupedAppointments(appointments)
      Object.values(timeGroupedAppointments).flat().forEach(a => {
        const type = a.status === 'Free' || a.status === 'Canceled' ? 'free' : a.service_name.startsWith('Initial') ? 'initial' : a.service_name.startsWith('Follow-up') ? 'followup' : null
        if (!type) return
        const passed = moment(a.start_date).add(a.duration, 'minutes').isBefore(now)
        const status = passed ? a.status === 'Confirmed' ? 'used' : 'wasted' : 'coming'
        result[status][type]++
      })
      return result
    },
    getChartOptions ({ data }) {
      data = {
        used: {
          initial: 0,
          followup: 0,
          free: 0,
        },
        wasted: {
          initial: 0,
          followup: 0,
          free: 0,
        },
        coming: {
          initial: 0,
          followup: 0,
          free: 0
        },
        ...data,
      }
      const categoryData = ['Total', 'Available', 'Booked', 'Wasted', 'Used']
      const initialSerialData = [
        data.used.initial,
        data.wasted.initial,
        data.coming.initial,
        null,
        data.used.initial + data.wasted.initial + data.coming.initial,
      ].reverse()
      const followupSerialData = [
        data.used.followup,
        data.wasted.followup,
        data.coming.followup,
        null,
        data.used.followup + data.wasted.followup + data.coming.followup,
      ].reverse()
      const freeSerialData = [
        null,
        data.wasted.free,
        null,
        data.coming.free,
        data.used.free + data.wasted.free + data.coming.free,
      ].reverse()
      return {
        title: {
          text: 'Appointment Summary'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Initial', 'Follow-up', 'Free']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: categoryData
        },
        series: [
          {
            name: 'Initial',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: initialSerialData
          },
          {
            name: 'Follow-up',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: followupSerialData
          },
          {
            name: 'Free',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: freeSerialData
          },
          {
            type: 'bar',
            stack: 'total',
            markPoint: {
              symbol: 'pin',
              symbolRotate: -90,
              label: {
                show: true,
                position: 'right',
              },
              data: initialSerialData.map((v, i) => v + followupSerialData[i] + freeSerialData[i]).map((value, yAxis) => ({
                value,
                xAxis: value,
                yAxis,

              }))
            },
          },
        ]
      }
    },
    handleDateRangeChange ([dateStart, dateEnd]) {
      this.filters.dateStart = dateStart
      this.filters.dateEnd = dateEnd
      this.$refs.chart.updateData()
    }
  }
}
</script>

<style lang="scss">
.calendar {
  --fc-small-font-size: 0.7em;

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
    margin: 1px 1px 1px 0;
    cursor: pointer;
  }
}

.filters {
  > * {
    margin: 0.5em;
  }
}
</style>
